/* src/components/Help.css */

.help-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .instructions,
  .code-example,
  .disclaimer {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .instructions:hover,
  .code-example:hover,
  .disclaimer:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  h1 {
    font-size: 2.5rem;
  } 
  
   h2 {
    font-size: 2rem;
  }
  
  ol {
    margin-left: 1.5rem;
  }
  
  ol li {
    margin-bottom: 1rem;
  }
  
  /* a {
    color: #007acc;
    text-decoration: none;
  } */
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .help-container {
      padding: 4px;
    }
  }
  