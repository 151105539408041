.card {
  width: 45ch;
  height: 45ch;
  background: grey;
  border-radius: 5px;
  background-image: url(https://res.cloudinary.com/bsingh6636/image/upload/v1725749746/projects/apiSerice/image3_qnqjha.webp);
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;
}

.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

/* @media screen and (max-width: 600px) { */
@media screen and (max-width : 600px){
  .card{
    width: 35ch;
    height: 35ch;
  }
}