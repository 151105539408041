/* styles.css */

.override-white-space {
  white-space: normal; /* or other values like 'wrap', 'pre-wrap', etc. */
}

.hover-scale {
  @apply transform transition-transform duration-300 ease-in-out hover:scale-defined;
}

.bg-gradient-dark {
  @apply bg-gradient-to-r from-gray-800 via-gray-900 to-black;
}

.bg-gradient-light {
  @apply bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900;
}

a:hover {
  text-decoration: none !important;
}

body {
  background-color: black;
  color: whitesmoke;
}

.components:hover{
  background-color: black !important;
  scale: 1.009 ;

}
.nav-link:hover, .nav-link:focus {
  background-color: #007BFF; /* Blue color for hover */
  scale: 1.05;
}

@media(max-width : 640px){
 
  body{
    font-size: 14px !important;
    /* background-color: wheat; */
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
