/* Shimmer.css */
.shimmer-container {
    min-height: 100vh;
    background: #1a1a1a;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .shimmer-header,
  .shimmer-footer {
    width: 100%;
    height: 2.5rem;
    background: #333;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .shimmer-content {
    width: 100%;
    max-width: 36rem;
    display: grid;
    gap: 1rem;
  }
  
  .shimmer-detail {
    height: 3rem;
    background: #333;
    border-radius: 0.5rem;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  
  .shimmer-detail:nth-child(odd) {
    background: linear-gradient(90deg, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.1) 75%);
  }
  